import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import DemoSection from '../../shared/DemoSection/DemoSection';
import FeatureSection from '../../shared/FeatureSection/FeatureSection';
import HeaderSection from '../../HeaderSection/HeaderSection';
import CheckList from '../../shared/CheckList/CheckList';

import Everyone from '../../../icons/everyone.inline.svg';

import './ForEveryone.scss';

const ForEveryone = () => {
  const {
    whatAreMissionsJson: { checkList },
    zuza,
    barbara,
    empolyeeMissonsImage,
    managerMissonsImage,
  } = useStaticQuery(graphql`
    {
      whatAreMissionsJson {
        checkList {
          section1 {
            description
            id
            title
            component
            checklistTitle
            icon {
              publicURL
            }
            elements {
              content
            }
          }
          section2 {
            description
            id
            title
            component
            checklistTitle
            icon {
              publicURL
            }
            elements {
              content
            }
          }
        }
      }
      zuza: file(relativePath: { eq: "rectangles/05.png" }) {
        childImageSharp {
          fixed(width: 64, height: 64) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      barbara: file(relativePath: { eq: "rectangles/01.png" }) {
        childImageSharp {
          fixed(width: 64, height: 64) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      empolyeeMissonsImage: file(
        relativePath: { regex: "/whatAreMissions/forEveryone/0/" }
      ) {
        extension
        id
        publicURL
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
            presentationWidth
          }
        }
      }
      managerMissonsImage: file(
        relativePath: { regex: "/whatAreMissions/forEveryone/1/" }
      ) {
        extension
        id
        publicURL
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
            presentationWidth
          }
        }
      }
    }
  `);

  const demoListEmpolyeeMissons = [
    {
      id: 0,
      thumbnail: {
        position: 'left',
        src: zuza,
      },
      shapePath:
        'M185.361 70.5272C249.115 24.1161 315.672 -28.3917 416.059 18.1657C487.118 51.1214 518.109 102.95 514.362 168.834C510.616 234.718 516.58 235.662 559.926 290.193C603.271 344.723 579.09 475.894 461.208 473.677C343.325 471.46 192.499 634.242 48.7026 450.193C-60.4038 310.378 26.2643 186.345 185.361 70.5272Z',
      content: {
        src: empolyeeMissonsImage,
        type: 'box-padding',
      },
    },
  ];

  const demoListManagerMissons = [
    {
      id: 0,
      thumbnail: {
        position: 'left',
        src: barbara,
      },
      shapePath:
        'M185.361 70.5272C249.115 24.1161 315.672 -28.3917 416.059 18.1657C487.118 51.1214 518.109 102.95 514.362 168.834C510.616 234.718 516.58 235.662 559.926 290.193C603.271 344.723 579.09 475.894 461.208 473.677C343.325 471.46 192.499 634.242 48.7026 450.193C-60.4038 310.378 26.2643 186.345 185.361 70.5272Z',
      content: {
        src: managerMissonsImage,
        type: 'box-padding',
      },
    },
  ];

  return (
    <div className="for-everyone">
      <HeaderSection
        icon={<Everyone />}
        subTitle="FOR EVERYONE"
        title="A library of hundreds of “missions” to secure people and organizations."
      />
      <FeatureSection
        className="for-everyone__empolyee-missons"
        isReversed
        firstColumn={
          <DemoSection demos={demoListEmpolyeeMissons} activeElement={0} />
        }
        secondColumn={<CheckList content={checkList.section1} />}
      />
      <FeatureSection
        className="for-everyone__manager-missons"
        firstColumn={
          <DemoSection demos={demoListManagerMissons} activeElement={0} />
        }
        secondColumn={<CheckList content={checkList.section2} />}
      />
    </div>
  );
};

export default ForEveryone;
