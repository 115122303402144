import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import HeaderSection from '../../HeaderSection/HeaderSection';
import FeatureSection from '../../shared/FeatureSection/FeatureSection';
import DemoSection from '../../shared/DemoSection/DemoSection';
import SliderSidebar from '../../shared/SliderSidebar/SliderSidebar';
import useSlider from '../../../utils/useSlider';

import Sectors from '../../../icons/sectors.inline.svg';

import useFeatureDescription from '../../../utils/useFeatureDescription';

import './SectorSpecific.scss';

const SectorSpecific = () => {
  const {
    whatAreMissionsJson: { sectorSpecific },
  } = useStaticQuery(graphql`
    {
      whatAreMissionsJson {
        sectorSpecific {
          content
          id
          title
          icon {
            publicURL
          }
          demo {
            shapePath
            content {
              type
              src {
                id
                extension
                publicURL
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_noBase64
                    presentationWidth
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const { isDesktopView } = useFeatureDescription();

  const featureList = sectorSpecific.map(elements => ({
    id: elements.id,
    title: elements.title,
    content: elements.content,
    icon: elements.icon.publicURL,
    collapsable: true,
  }));

  const demoList = sectorSpecific.map(elements => ({
    ...elements.demo,
    id: elements.id,
  }));

  const {
    progressValue,
    activeSlideId,
    setSlide,
    stopAutoPlay,
    sliderElementRef,
  } = useSlider(featureList.length, 4000);

  const sectionDescription =
    'Edwin drives change in your organization’s security posture, whatever your industry and expertise.';

  return (
    <div ref={sliderElementRef} className="sector-specific">
      <div className="container">
        <HeaderSection
          icon={<Sectors />}
          subTitle="SECTOR SPECIFIC"
          title="Helping secure businesses around the world"
          description={!isDesktopView ? sectionDescription : ''}
        />
      </div>
      <FeatureSection
        rowViewOnTablet
        firstColumn={
          <DemoSection demos={demoList} activeElement={activeSlideId} />
        }
        secondColumn={
          <SliderSidebar
            description={isDesktopView ? sectionDescription : ''}
            boxes={featureList}
            collapsable={false}
            activeElementId={activeSlideId}
            setActiveElement={setSlide}
            columnViewOnTablet={false}
            stopAutoPlay={stopAutoPlay}
            progressValue={progressValue}
          />
        }
      />
    </div>
  );
};

export default SectorSpecific;
