import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import SliderSection from '../../shared/SliderSection/SliderSection';

const SecondHumanFeature = () => {
  const {
    whatAreMissionsJson: { humanFeature },
  } = useStaticQuery(graphql`
    {
      whatAreMissionsJson {
        humanFeature {
          slider2 {
            id
            shapePath
            text
            image {
              src {
                id
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                    presentationWidth
                  }
                }
              }
              flair {
                top
                left
                rotate
              }
            }
          }
        }
      }
    }
  `);

  return (
    <SliderSection
      type="right"
      sliderContent={[humanFeature.slider2]}
      button={{ onClick: '/book-demo', text: 'Book a Demo' }}
    />
  );
};

export default SecondHumanFeature;
