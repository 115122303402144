import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import HeaderSection from '../../HeaderSection/HeaderSection';
import FeatureSection from '../../shared/FeatureSection/FeatureSection';
import DemoSection from '../../shared/DemoSection/DemoSection';

import Drawer from '../../../icons/drawer.inline.svg';
import ShieldIcon from '../../../icons/government/shield.inline.svg';
import MissionsIcon from '../../../icons/government/missions.inline.svg';
import CheckIcon from '../../../icons/government/check.inline.svg';
import ScopeIcon from '../../../icons/government/scope.inline.svg';

import './Government.scss';

const boxes = [
  {
    icon: <ShieldIcon />,
    title: 'Edwin Essentials',
    content:
      'A set of security missions for individuals, employees, and organizations that cover behaviors related to company data, passwords, emails, work areas, working remotely, and more.',
  },
  {
    icon: <MissionsIcon />,
    title: 'Industry missions',
    content:
      'Every industry has unique security needs. Edwin’s industry-specific missions are tailored to these requirements whether in banking, education, healthcare, or other verticals.',
  },
  {
    icon: <CheckIcon />,
    title: 'Regulatory missions',
    content:
      'Edwin provides a set of tailored missions that cover regulatory cybersecurity compliance related to data protection and privacy. Missions cover compliance for HIPAA, PCI, GDPR, SOC 2, SOX, and more.',
  },
  {
    icon: <ScopeIcon />,
    title: 'Platform security',
    content:
      'Whether you’re a business owner or IT director, ensure you’re getting the most out of your existing tools. Edwin has designed missions that ensure you are using the most secure configurations of software such as Office 365, G Suite, Dropbox, Slack, and AWS.',
  },
];

const renderInfoBox = box => (
  <div key={box.title} className="government__box">
    {box.icon}
    <h3>
      <strong>{box.title}</strong>
    </h3>
    <p>{box.content}</p>
  </div>
);

const OurCommitment = () => {
  const { image } = useStaticQuery(graphql`
    {
      image: file(relativePath: { regex: "/whatAreMissions/government/0/" }) {
        extension
        id
        publicURL
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
            presentationWidth
          }
        }
      }
    }
  `);

  const demoList = [
    {
      id: 0,
      shapePath:
        'M185.361 70.5274C249.115 24.1164 315.672 -28.3915 416.059 18.1659C487.118 51.1216 518.109 102.95 514.362 168.834C510.616 234.718 516.58 235.663 559.926 290.193C603.271 344.723 579.09 475.894 461.208 473.677C343.325 471.46 192.499 634.243 48.7026 450.193C-60.4038 310.378 26.2643 186.345 185.361 70.5274Z',
      content: {
        type: 'full-width',
        src: image,
      },
    },
  ];

  return (
    <div className="government">
      <div className="container">
        <HeaderSection
          icon={<Drawer />}
          subTitle="GOVERNMENT-BACKED STANDARDS"
          title="Our missions are built on government-backed standards that help protect your organization."
          description="Edwin protects your organization - whatever its size - against a vast variety of common cybersecurity risks. Based on the UK’s Cyber Essentials and the US’s NIST framework, Edwin’s missions help change the security behaviors that impact people, their families, and their organizations."
        />
      </div>
      <FeatureSection
        rowViewOnTablet={false}
        firstColumn={<DemoSection demos={demoList} activeElement={0} />}
        secondColumn={
          <div className="government__boxes">{boxes.map(renderInfoBox)}</div>
        }
      />
    </div>
  );
};

export default OurCommitment;
