import React from 'react';
import HeaderSection from '../../HeaderSection/HeaderSection';
import MissionIcon from '../../../icons/mission.inline.svg';

import Shape from '../../../icons/missions-types/shape.inline.svg';
import OneTime from '../../../icons/missions-types/oneTime.inline.svg';
import Preventive from '../../../icons/missions-types/preventive.inline.svg';
import Dynamic from '../../../icons/missions-types/dynamic.inline.svg';

import './MissionsTypes.scss';

const MissionsTypes = () => {
  return (
    <div className="missions-types">
      <div className="container missions-types__container">
        <HeaderSection
          icon={<MissionIcon />}
          subTitle="Mission Types"
          title="Missions to tackle any security behavior."
        />
        <div className="missions-types__wrapper">
          <div className="mission-types__type">
            <div className="misson-types__type-image">
              <OneTime className="misson-types__icon" />
              <Shape className="misson-types__shape" />
            </div>
            <div className="misson-types__content">
              <h3>One-time missions</h3>
              <p>
                Device and service-specific missions such as setting up 2-factor
                authentication, and ensuring computers have password locks.
              </p>
            </div>
          </div>

          <div className="mission-types__type">
            <div className="misson-types__type-image">
              <Preventive className="misson-types__icon" />
              <Shape className="misson-types__shape" />
            </div>
            <div className="misson-types__content">
              <h3>Preventive missions</h3>
              <p>
                Ongoing security best practices such as not forwarding work
                email to personal accounts, and locking computers when away.
              </p>
            </div>
          </div>

          <div className="mission-types__type">
            <div className="misson-types__type-image">
              <Dynamic className="misson-types__icon" />
              <Shape className="misson-types__shape" />
            </div>
            <div className="misson-types__content">
              <h3>Dynamic missions</h3>
              <p>
                Changes to the external threat landscape trigger new security
                behaviors such as events that might require new software
                updates.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissionsTypes;
