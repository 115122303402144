import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import QuoteSection from '../../shared/QuoteSection/QuoteSection';
import QuoteIcon6 from '../../../icons/ilustration_07.inline.svg';
import shapePaths from '../../../utils/shapePaths';

import './FirstQuote.scss';

const FirstQuote = () => {
  const { thumbnail } = useStaticQuery(graphql`
    {
      thumbnail: file(relativePath: { eq: "rectangles/08.png" }) {
        childImageSharp {
          fixed(width: 64, height: 64) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `);
  return (
    <QuoteSection
      className="what-are-missions__first-quote"
      content={
        <span>
          “Changing behaviors starts with understanding risk and taking small
          steps to make habits that last.”
        </span>
      }
      shapePath={shapePaths[10]}
      author={{
        name: 'John Coursen',
        title: 'Co-Founder & Chief Security Officer',
        image: thumbnail.childImageSharp.fixed,
      }}
      image={<QuoteIcon6 />}
    />
  );
};

export default FirstQuote;
