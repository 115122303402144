import React from 'react';

import Layout from '../components/layout';
import Seo from '../components/seo';

import Header from '../components/Header/Header';
import Introduction from '../components/shared/Introduction/Introduction';

import Customers from '../components/shared/Customers/Customers';
import FinalCta from '../components/shared/FinalCta/FinalCta';
import MissionsTypes from '../components/WhatAreMissions/MissionsTypes/MissionsTypes';
import ForEveryone from '../components/WhatAreMissions/ForEveryone/ForEveryone';
import FirstHumanFeature from '../components/WhatAreMissions/HumanFeature/FirstHumanFeature';
import SectorSpecific from '../components/WhatAreMissions/SectorSpecific/SectorSpecific';
import SecondHumanFeature from '../components/WhatAreMissions/HumanFeature/SecondHumanFeature';
import Government from '../components/WhatAreMissions/Government/Government';
import FirstQuote from '../components/WhatAreMissions/FirstQuote/FirstQuote';

const WhatAreMissions = () => (
  <Layout>
    <Header demoButtonNavigation="/book-demo" />
    <Seo
      title="Edwin - Cybersecurity designed for humans"
      description="Edwin is a behavioral cybersecurity platform that helps organizations reduce security risks, comply with policies, and shape their security culture through small, ongoing, and verifiable security missions."
    />
    <Introduction
      isReversed
      listElements={[
        'Change people’s behaviors',
        'Create lasting security habits',
        'Demonstrate ROI to your organization',
        'Dynamically update as the security landscape changes',
      ]}
      title={
        <h2>
          <span className="text-main-color">
            Edwin’s missions help change the security behaviors
          </span>{' '}
          that impact people, their families, <br /> and their organizations.
        </h2>
      }
    />
    <MissionsTypes />
    <ForEveryone />
    <FirstHumanFeature />
    <SectorSpecific />
    <SecondHumanFeature />
    <Government />
    <FirstQuote />
    <Customers />
    <FinalCta />
  </Layout>
);

export default WhatAreMissions;
